import { info } from "@/common/utils/console";

// Import jquery global
import "@/common/globals_node_modules/jquery";
import "bootstrap-sass/assets/javascripts/bootstrap";
import "@/lib/appsignal";

// Disable submit buttons on submit
import "../react/sprockets/lib/rails_ujs/init";

import $ from "jquery";

$(() => {
  info("==== login pack loading finished");
});
